<template>
  <div class="dashboard-container">
    <ConfirmDialog />
    <div class="left-panel" :class="{ 'collapsed': isSidebarCollapsed }">
      <div class="daily-plan-header">
        <h4>Daily Plan</h4>
      </div>
      <div class="plan-column" ref="planColumn">
        <div v-for="hour in visibleHours" :key="hour" class="hour-block" :class="{ 'current-hour': hour === currentHour }" :ref="setHourRef">
          <span class="hour-label">{{ hour }}:00</span>
          <div 
            class="task-display"
            @click="openModal(hour)"
          >
            {{ dailyPlan[hour] || '' }}
          </div>
        </div> 
      </div>
      <div class="clear-plan-button">
        <button @click="confirmClearDailyPlan" title="Clear Daily Plan">clear plan </button>
      </div>
      <!-- Keep Pomodoro timer here -->
      <div id="pomodoro">
        <button id="startTimer" @click="togglePomodoroTimer">
          <span :class="{ rocking: isTimerRunning && !isTimerPaused }">🍅</span> 
          {{ isTimerRunning ? (isTimerPaused ? 'Resume' : 'Pause') : 'Start' }} 
          <span id="timerDisplay">{{ formattedTime }}</span>
        </button>
        <button id="resetTimer" @click="resetTimer">Reset</button>
        <div id="countDisplay">Completed 🍅: {{ '✓'.repeat(completedCount) }}</div>
      </div>
      <div id="weather">
        <div v-if="weather">
            <p>{{ weather.name }} {{ weather.main.temp }}° {{ weather.weather[0].description }}.</p>
            <p>Made with love by <a href="https://plans.lol" style="text-transform: lowercase;">plans.lol</a> 😂</p>
        </div>
        <div v-else>
            <p>Loading weather data...</p>
        </div>
      </div>
    </div>
    <div class="main-content">
      <div class="header-container">
        <div class="dark-mode-toggle">
          <button class="toggle" @click="toggleDarkMode" :class="{ 'active': isDarkMode }">
            <span class="toggle-track">
              <span class="toggle-indicator">
                <span class="checkMark">
                  <svg viewBox="0 0 24 24" id="ghq-svg-check" role="presentation" aria-hidden="true">
                    <path d="M9.86 18a1 1 0 01-.73-.32l-4.86-5.17a1.001 1.001 0 011.46-1.37l4.12 4.39 8.41-9.2a1 1 0 111.48 1.34l-9.14 10a1 1 0 01-.73.33h-.01z"></path>
                  </svg>
                </span>
              </span>
            </span>
            {{ isDarkMode ? '☾' : '☀' }}
          </button>
        </div>
          <div class="sidebar-toggle" @click="toggleSidebar" :class="{ 'collapsed': isSidebarCollapsed }">
            {{ isSidebarCollapsed ? '→' : '←' }}
          </div>
          <div class="user-auth">
            <div v-if="user">
                <p>Welcome, {{ user.displayName }} <a href="#" @click.prevent="signOut" class="button-link">Sign Out</a></p>
            </div>
            <button v-else @click="signIn">Sign In with Google</button>
        </div>
      </div>
      <h4>Management Dashboard</h4>
      <p>This is based on Jamie Dimon's management technique for organizing his day.</p>
      <div class="quadrants">
        <div class="quadrant" v-for="(item, index) in texts" :key="index">
          <label :for="item.id">{{ item.name }}</label>
          <textarea 
            :id="item.id"
            v-model="item.content" 
            @input="saveTexts" 
            :placeholder="item.placeholder || ''"
          ></textarea>
        </div>
      </div>
      <div class="app-links">
        <a href="x-apple-reminderkit://">Reminders</a> |  
        <a href="obsidian://open">Obsidian</a> |  
        <a href="ms-outlook://">Outlook</a> |
        <a href="https://ankiuser.net/study" target="_blank">Anki</a>
      </div>
      <div v-if="githubUsername" class="calendar">
        <div id="github-calendar-container"></div>
      </div>
    </div>
    <TaskModal 
      :show="showModal"
      :hour="selectedHour"
      :initialTask="dailyPlan[selectedHour] || ''"
      @save="saveTask"
      @close="closeModal"
    />
  </div>
</template>

<script>
import '../styles.css';
import { ref, onMounted, watch, nextTick } from 'vue';
import { getDatabase, ref as dbRef, onValue, set, get } from 'firebase/database';
import axios from 'axios';
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut, onAuthStateChanged } from 'firebase/auth';
import TaskModal from './TaskModal.vue';
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from 'primevue/confirmdialog';
import { usePermission } from '@vueuse/core';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../firebaseConfig';
import GitHubCalendar from 'github-calendar';
import 'github-calendar/dist/github-calendar-responsive.css';
import confetti from 'canvas-confetti';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getDatabase(app);

export default {
    components: {
        TaskModal,
        ConfirmDialog
    },
    setup() {
        const confirm = useConfirm();
        const notificationPermission = usePermission('notifications');
        const githubUsername = ref(null);

        const loadUserProfile = () => {
          const user = auth.currentUser;
          if (user) {
            const userProfileRef = dbRef(db, `users/${user.uid}/profile`);
            onValue(userProfileRef, (snapshot) => {
              const profile = snapshot.val();
              if (profile && profile.githubUsername) {
                githubUsername.value = profile.githubUsername.replace('@', ''); // Remove @ if present
                console.log('GitHub username loaded:', githubUsername.value);
              } else {
                githubUsername.value = null;
                console.log('No GitHub username found in profile');
              }
            });
          }
        };

        const initGitHubCalendar = () => {
          if (githubUsername.value) {
            console.log('Initializing GitHub Calendar for:', githubUsername.value);
            try {
              GitHubCalendar("#github-calendar-container", githubUsername.value, { 
                responsive: true,
                tooltips: true
              }).then(() => {
                console.log('GitHub Calendar initialized successfully');
              }).catch((error) => {
                console.error('Error initializing GitHub Calendar:', error);
              });
            } catch (error) {
              console.error('Error in GitHubCalendar function:', error);
            }
          } else {
            console.log('Cannot initialize GitHub Calendar: No username available');
          }
        };

        onMounted(() => {
          console.log('ManagementQuadrant mounted');
          loadUserProfile();
        });

        watch(githubUsername, (newValue) => {
          if (newValue) {
            console.log('GitHub username changed, reinitializing calendar');
            nextTick(() => {
              initGitHubCalendar();
            });
          }
        });

        return {
            confirm,
            notificationPermission,
            githubUsername,
            auth,
            db,
            initGitHubCalendar
        };
    },
    data() {
        return {
            texts: [
                { id: 'todos', name: 'Today\'s Todos', content: '', placeholder: 'List your tasks for today...' },
                { id: 'praise', name: 'Who should I praise?', content: '', placeholder: 'Think of someone who deserves recognition...' },
                { id: 'avoid', name: 'What shouldn\'t I get?', content: '', placeholder: 'Note things that should have been delegated or reassigned...' },
                { id: 'counsel', name: 'Who should I counsel?', content: '', placeholder: 'Consider who might need your advice or support...' }
            ],
            timer: null,
            timeLeft: this.getSavedTimeLeft(), // Initialize with saved time or default
            completedCount: 0,
            weather: null,
            zipCode: null, // Initialize zipCode as null
            weatherApiKey: '4053cd49f4192aadd87016e78753314b',
            // weatherApiKey: process.env.VUE_APP_WEATHER_API_KEY,
            isDarkMode: false,
            systemPrefersDark: false,
            user: null,
            isTimerRunning: false,
            isTimerPaused: false,
            hours: Array.from({ length: 24 }, (_, i) => i), // Array of hours from 0 to 23
            dailyPlan: {}, // Object to hold tasks for each hour
            currentHour: this.getCurrentHour(),
            showModal: false,
            selectedHour: null,
            scrollTimeout: null,
            hourRefs: {},
            updateCurrentHourInterval: null,
            lastResetDate: null,
            isSidebarCollapsed: false,
        }
    },
    computed: {
        formattedTime() {
            let minutes = Math.floor(this.timeLeft / 60);
            let seconds = this.timeLeft % 60;
            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        },
        filteredTexts() {
            return this.texts.filter(item => item.name !== 'Daily Plan');
        },
        visibleHours() {
          const currentHour = this.getCurrentHour();
          const startHour = Math.max(0, currentHour - 2);
          const endHour = Math.min(23, currentHour + 8);
          return this.hours.filter(hour => hour >= startHour && hour <= endHour);
        }
    },
    methods: {
        startPomodoroTimer() {
            if (this.timer) {
                clearInterval(this.timer);
            }
            this.isTimerRunning = true;
            this.isTimerPaused = false;
            this.timer = setInterval(() => {
                if (this.timeLeft > 0) {
                    this.timeLeft -= 1;
                    this.saveTimerState(); // Save state on each tick
                } else {
                    this.completePomodoro();
                }
            }, 1000);
        },
        pauseTimer() {
            if (this.timer) {
                clearInterval(this.timer);
            }
            this.isTimerPaused = true;
        },
        resumeTimer() {
            this.isTimerPaused = false;
            this.startPomodoroTimer();
        },
        resetTimer() {
            if (this.timer) {
                clearInterval(this.timer);
            }
            this.timeLeft = 25 * 60;
            this.isTimerRunning = false;
            this.isTimerPaused = false;
            this.saveTimerState();
        },
        completePomodoro() {
            clearInterval(this.timer);
            this.checkAndResetPomodoro(); // Check before incrementing
            this.completedCount += 1;
            this.timeLeft = 25 * 60;
            this.isTimerRunning = false;
            this.isTimerPaused = false;
            this.saveTimerState();
            
            // Add desktop notification
            this.showNotification('Pomodoro Completed', 'Time for a break!');

            // Always celebrate completion, but check visibility for audio cues if you have any
            this.celebrateCompletion();

            if (document.visibilityState !== 'visible') {
              const celebrateOnFocus = () => {
                // You could add audio cues here if the page wasn't visible
                document.removeEventListener('visibilitychange', celebrateOnFocus);
              };
              document.addEventListener('visibilitychange', celebrateOnFocus);
            }
        },
        showNotification(title, body) {
            if ('Notification' in window) {
                Notification.requestPermission().then(permission => {
                    if (permission === 'granted') {
                        new Notification(title, {
                            body: body,
                            icon: '/path/to/your/icon.png' // Optional: Add a path to your app's icon
                        });
                    }
                });
            }
        },
        saveTimerState() {
            if (this.user) {
                const userId = this.user.uid;
                const timerStateRef = dbRef(db, `users/${userId}/timerState`);
                set(timerStateRef, {
                    timeLeft: this.timeLeft,
                    isTimerRunning: this.isTimerRunning,
                    isTimerPaused: this.isTimerPaused,
                    completedCount: this.completedCount,
                    lastResetDate: this.lastResetDate,
                }).then(() => {
                    console.log('Timer state saved successfully');
                }).catch((error) => {
                    console.error('Error saving timer state:', error);
                });
            } else {
                console.warn('Cannot save timer state: User not logged in');
            }
        },
        loadTimerState() {
            if (this.user) {
                const userId = this.user.uid;
                const timerStateRef = dbRef(db, `users/${userId}/timerState`);
                onValue(timerStateRef, (snapshot) => {
                    const data = snapshot.val();
                    if (data) {
                        this.timeLeft = data.timeLeft || 25 * 60;
                        this.isTimerRunning = data.isTimerRunning || false;
                        this.isTimerPaused = data.isTimerPaused || false;
                        this.completedCount = data.completedCount || 0;
                        this.lastResetDate = data.lastResetDate || null;
                        console.log('Timer state loaded:', data);
                        this.checkAndResetPomodoro();
                    } else {
                        console.log('No timer state found, using default values');
                    }
                }, (error) => {
                    console.error('Error loading timer state:', error);
                });
            } else {
                console.warn('Cannot load timer state: User not logged in');
            }
        },
        getSavedTimeLeft() {
            const savedTime = localStorage.getItem('pomodoroTimeLeft');
            return savedTime ? parseInt(savedTime) : 25 * 60;
        },
        getSavedCompletedCount() {
            const savedCount = localStorage.getItem('pomodoroCompletedCount');
            return savedCount ? parseInt(savedCount) : 0;
        },
        saveTexts() {
            if (this.user) {
                const userId = this.user.uid;
                const quadrantsRef = dbRef(db, `users/${userId}/quadrants`);
                const quadrantsData = {
                    todos: this.texts.find(item => item.id === 'todos')?.content || '',
                    praise: this.texts.find(item => item.id === 'praise')?.content || '',
                    avoid: this.texts.find(item => item.id === 'avoid')?.content || '',
                    counsel: this.texts.find(item => item.id === 'counsel')?.content || ''
                };
                set(quadrantsRef, quadrantsData)
                    .then(() => {
                        console.log('Quadrants saved successfully.');
                    })
                    .catch((error) => {
                        console.error('Error saving quadrants:', error);
                    });
            }
        },
        loadTexts() {
            if (this.user) {
                const userId = this.user.uid;
                const quadrantsRef = dbRef(db, `users/${userId}/quadrants`);
                onValue(quadrantsRef, (snapshot) => {
                    const data = snapshot.val();
                    console.log('Data loaded from Firebase:', data); // For debugging

                    if (data) {
                        this.texts = [
                            { id: 'todos', name: 'Today\'s Todos', content: data.todos || '', placeholder: 'List your tasks for today...' },
                            { id: 'praise', name: 'Who should I praise?', content: data.praise || '', placeholder: 'Think of someone who deserves recognition...' },
                            { id: 'avoid', name: 'What shouldn\'t I get?', content: data.avoid || '', placeholder: 'Note things that should have been delegated or reassigned...' },
                            { id: 'counsel', name: 'Who should I counsel?', content: data.counsel || '', placeholder: 'Consider who might need your advice or support...' }
                        ];
                    } else {
                        // Initialize with default texts if no data exists
                        this.texts = [
                            { id: 'todos', name: 'Today\'s Todos', content: '', placeholder: 'List your tasks for today...' },
                            { id: 'praise', name: 'Who should I praise?', content: '', placeholder: 'Think of someone who deserves recognition...' },
                            { id: 'avoid', name: 'What shouldn\'t I get?', content: '', placeholder: 'Note things that should have been delegated or reassigned...' },
                            { id: 'counsel', name: 'Who should I counsel?', content: '', placeholder: 'Consider who might need your advice or support...' }
                        ];
                    }
                });
            }
        },
        saveDailyPlan() {
          if (this.user) {
            const userId = this.user.uid;
            const dailyPlanRef = dbRef(db, `users/${userId}/dailyPlan`);
            set(dailyPlanRef, this.dailyPlan)
              .then(() => {
                console.log('Daily plan saved successfully');
              })
              .catch((error) => {
                console.error('Error saving daily plan:', error);
              });
          } else {
            console.warn('Cannot save daily plan: User not logged in');
          }
        },
        loadDailyPlan() {
          if (this.user) {
            const userId = this.user.uid;
            const dailyPlanRef = dbRef(db, `users/${userId}/dailyPlan`);
            onValue(dailyPlanRef, (snapshot) => {
              const data = snapshot.val();
              if (data) {
                this.dailyPlan = data;
                console.log('Daily plan loaded:', this.dailyPlan);
              } else {
                this.dailyPlan = {};
                console.log('No daily plan data found, initialized empty object');
              }
            });
          } else {
            console.warn('Cannot load daily plan: User not logged in');
          }
        },
        handleDailyPlanInput(hour) {
            console.log(`Updating plan for hour: ${hour}`);
            this.saveDailyPlan();
        },
        async fetchWeather() {
            if (!this.zipCode) {
                console.error('No zip code available for weather fetch');
                return;
            }
            try {
                const response = await axios.get(`https://api.openweathermap.org/data/2.5/weather`, {
                    params: {
                        zip: this.zipCode,
                        appid: this.weatherApiKey,
                        units: 'imperial'
                    }
                });
                this.weather = response.data;
                console.log('Weather data:', this.weather);
            } catch (error) {
                console.error('Error fetching weather data:', error);
            }
        },
        toggleDarkMode() {
            this.isDarkMode = !this.isDarkMode;
            document.body.classList.toggle('dark-mode', this.isDarkMode);
            // Save the preference to localStorage
            localStorage.setItem('darkMode', JSON.stringify(this.isDarkMode));
            this.updateGitHubCalendarTheme();
        },
        updateGitHubCalendarTheme() {
            if (this.githubUsername) {
                console.log('Updating GitHub Calendar theme');
                try {
                    GitHubCalendar("#github-calendar-container", this.githubUsername, { 
                        responsive: true,
                        tooltips: true,
                        theme: this.isDarkMode ? 'dark' : 'light'
                    }).then(() => {
                        console.log('GitHub Calendar theme updated successfully');
                    }).catch((error) => {
                        console.error('Error updating GitHub Calendar theme:', error);
                    });
                } catch (error) {
                    console.error('Error in GitHubCalendar function:', error);
                }
            } else {
                console.log('Cannot update GitHub Calendar theme: No username available');
            }
        },
        initializeAuth() {
          onAuthStateChanged(auth, (user) => {
            this.user = user;
            if (user) {
              console.log('User logged in, loading daily plan');
              this.loadDailyPlan();
              this.loadTexts();
            } else {
              console.log('User logged out, resetting daily plan');
              this.dailyPlan = {};
              this.resetTexts();
            }
          });
        },
        async signIn() {
            const provider = new GoogleAuthProvider();
            try {
                await signInWithPopup(auth, provider);
            } catch (error) {
                console.error('Error signing in:', error);
            }
        },
        async signOut() {
            try {
                await signOut(auth);
                this.resetTexts();
            } catch (error) {
                console.error('Error signing out:', error);
            }
        },
        resetTexts() {
            this.texts.forEach(text => text.content = '');
        },
        async togglePomodoroTimer() {
            if (this.notificationPermission.value !== 'granted') {
                await this.requestNotificationPermission();
            }
            
            if (this.isTimerRunning) {
                if (this.isTimerPaused) {
                    this.resumeTimer();
                } else {
                    this.pauseTimer();
                }
            } else {
                this.startPomodoroTimer();
            }
        },
        async requestNotificationPermission() {
            try {
                const permission = await Notification.requestPermission();
                console.log('Notification permission:', permission);
            } catch (error) {
                console.error('Error requesting notification permission:', error);
            }
        },
        getCurrentHour() {
            return new Date().getHours();
        },
        updateCurrentHour() {
            const newCurrentHour = this.getCurrentHour();
            if (newCurrentHour !== this.currentHour) {
                this.currentHour = newCurrentHour;
                this.$nextTick(() => {
                    this.scrollToCurrentHour();
                });
            }
        },
        scrollToCurrentHour() {
            const currentHourBlock = this.hourRefs[this.currentHour];
            const planColumn = this.$refs.planColumn;
            
            if (currentHourBlock && planColumn) {
                const scrollPosition = currentHourBlock.offsetTop - planColumn.clientHeight / 2 + currentHourBlock.clientHeight / 2;
                planColumn.scrollTop = scrollPosition;
            }
        },
        handleResize() {
            if (this.scrollTimeout) {
                clearTimeout(this.scrollTimeout);
            }
            this.scrollTimeout = setTimeout(() => {
                this.scrollToCurrentHour();
            }, 100);
        },
        openModal(hour) {
            this.selectedHour = hour;
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
            this.selectedHour = null;
        },
        saveTask({ hour, task }) {
          this.dailyPlan[hour] = task;
          this.saveDailyPlan();
        },
        handleKeyDown(e) {
            if (e.key === 'Escape' && this.showModal) {
                this.closeModal();
            }
        },
        confirmClearDailyPlan() {
            this.confirm.require({
                message: 'Are you sure you want to clear the entire daily plan?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.clearDailyPlan();
                },
                reject: () => {
                    // Optional: handle rejection
                }
            });
        },
        clearDailyPlan() {
          this.dailyPlan = {};
          this.saveDailyPlan();
        },
        adjustHeight(event) {
            const textarea = event.target;
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        },
        setHourRef(el) {
            if (el) {
                const hour = parseInt(el.querySelector('.hour-label').textContent);
                this.hourRefs[hour] = el;
            }
        },
        async loadUserProfile() {
          const user = auth.currentUser;
          if (user) {
            const userProfileRef = dbRef(db, `users/${user.uid}/profile`);
            const snapshot = await get(userProfileRef);
            if (snapshot.exists()) {
              const profile = snapshot.val();
              this.zipCode = profile.zipCode;
              console.log('Loaded zip code:', this.zipCode);
              this.fetchWeather(); // Call fetchWeather after loading the zip code
            }
          }
        },
        celebrateCompletion() {
          const scalar = 2;
          const unicorn = confetti.shapeFromText({ text: '😂', scalar });

          const defaults = {
            spread: 360,
            ticks: 60,
            gravity: 0,
            decay: 0.96,
            startVelocity: 20,
            shapes: [unicorn],
            scalar
          };

          const shoot = () => {
            confetti({
              ...defaults,
              particleCount: 30
            });

            confetti({
              ...defaults,
              particleCount: 5,
              flat: true
            });

            confetti({
              ...defaults,
              particleCount: 15,
              scalar: scalar / 2,
              shapes: ['circle']
            });
          };

          shoot();
          setTimeout(shoot, 100);
          setTimeout(shoot, 200);
        },
        // New method to expose completePomodoro to the global scope
        exposeCompletePomodoroToConsole() {
          window.completePomodoro = this.completePomodoro.bind(this);
          console.log('You can now call completePomodoro() from the console to test timer completion.');
        },
        checkAndResetPomodoro() {
          const today = new Date().toDateString();
          if (this.lastResetDate !== today) {
            this.completedCount = 0;
            this.lastResetDate = today;
            this.saveTimerState();
            console.log('Pomodoro count reset for new day');
          }
        },
        toggleSidebar() {
          this.isSidebarCollapsed = !this.isSidebarCollapsed;
        },
    },
    mounted() {
        this.loadTexts();
        this.fetchWeather();
        window.vueInstance = this;  // Add this line
        
        // Check if there's an active timer and resume it
        if (this.timeLeft < 25 * 60) {
            this.startPomodoroTimer();
        }

        // Load the saved preference from localStorage
        const savedDarkMode = localStorage.getItem('darkMode');
        if (savedDarkMode !== null) {
            this.isDarkMode = JSON.parse(savedDarkMode);
            document.body.classList.toggle('dark-mode', this.isDarkMode);
        }

        this.initializeAuth();
        
        // Move loadTimerState to be called after user authentication
        onAuthStateChanged(auth, (user) => {
          if (user) {
            this.user = user;
            this.loadTimerState(); // Load timer state as soon as user is authenticated
            this.loadDailyPlan();
          } else {
            this.user = null;
            // Reset timer state when user logs out
            this.timeLeft = 25 * 60;
            this.isTimerRunning = false;
            this.isTimerPaused = false;
            this.completedCount = 0;
            this.lastResetDate = null;
          }
        });

        // Update current hour every minute
        this.updateCurrentHourInterval = setInterval(this.updateCurrentHour, 60000);

        document.addEventListener('keydown', this.handleKeyDown);

        // Add this to automatically resize textareas
        this.$nextTick(() => {
            document.querySelectorAll('.quadrant textarea').forEach(textarea => {
                textarea.addEventListener('input', this.adjustHeight);
                
                // Initial resize
                textarea.style.height = 'auto';
                textarea.style.height = `${textarea.scrollHeight}px`;
            });
        });

        this.$nextTick(() => {
            this.scrollToCurrentHour();
        });
        window.addEventListener('resize', this.handleResize);
        this.initGitHubCalendar();
        this.loadUserProfile(); // Load user profile (including zip code) when component mounts
        this.exposeCompletePomodoroToConsole();
        this.loadTimerState();
        // Set up an interval to check and reset the Pomodoro count
        setInterval(this.checkAndResetPomodoro, 60000); // Check every minute

        // Load the daily plan when the component is mounted
        // this.loadDailyPlan();
    },
    beforeUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
        document.querySelectorAll('.quadrant textarea').forEach(textarea => {
            textarea.removeEventListener('input', this.adjustHeight);
        });
        window.removeEventListener('resize', this.handleResize);
        if (this.scrollTimeout) {
            clearTimeout(this.scrollTimeout);
        }
        clearInterval(this.updateCurrentHourInterval);
    }
};
</script>

<style scoped>
/* ... other styles ... */

#github-calendar-container {
  max-width: 100%;
  overflow-x: auto;
}

.calendar {
  margin-top: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Make GitHub calendar cells square */
:deep(.js-calendar-graph-svg) {
  overflow: visible !important;
}

:deep(.ContributionCalendar-day) {
  width: 10px !important;
  height: 10px !important;
  rx: 2px !important;
  ry: 2px !important;
}

/* Adjust spacing between cells */
:deep(.js-calendar-graph-svg > g) {
  transform: translate(16px, 20px);
}

:deep(.ContributionCalendar-grid) {
  gap: 3px;
}

/* Dark mode styles for GitHub calendar */
:global(.dark-mode) .calendar {
  background-color: #0d1117;
}

:global(.dark-mode) .js-calendar-graph-svg {
  color: #c9d1d9;
}

:global(.dark-mode) .ContributionCalendar-label {
  fill: #c9d1d9;
}

:global(.dark-mode) .ContributionCalendar-day {
  fill: #161b22;
  stroke: #21262d;
}

:global(.dark-mode) .ContributionCalendar-day[data-level="1"] { fill: #0e4429; }
:global(.dark-mode) .ContributionCalendar-day[data-level="2"] { fill: #006d32; }
:global(.dark-mode) .ContributionCalendar-day[data-level="3"] { fill: #26a641; }
:global(.dark-mode) .ContributionCalendar-day[data-level="4"] { fill: #39d353; }

:global(.dark-mode) .graph-before-activity-overview {
  border-color: #30363d;
}

:global(.dark-mode) .day-tooltip {
  background-color: #161b22;
  color: #c9d1d9;
  border-color: #30363d;
}

:global(.dark-mode) .text-muted {
  color: #8b949e !important;
}

.clear-plan-button {
  margin-bottom: 10px;
  text-align: right; /* This will align the button to the right */
  display: flex;
  justify-content: flex-end; /* This will push the button to the right */
}

.clear-plan-button button {
  background-color: transparent;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  font-size: 14px;
  padding: 5px 10px;
  display: inline-flex; /* Changed from flex to inline-flex */
  align-items: center;
  transition: color 0.3s ease;
}

.clear-plan-button button:hover {
  color: #ff4136; /* Red color on hover */
}

.left-panel {
  width: 250px;
  border-right: 1px solid var(--border-color);
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease, padding 0.3s ease;
}

.left-panel.collapsed {
  width: 0;
  padding: 0;
  overflow: hidden;
}

.daily-plan-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.daily-plan-header h4 {
  margin: 0;
}

.sidebar-toggle {
  position: relative;
  background-color: var(--primary-color);
  color: white;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: left 0.3s ease;
}

.sidebar-toggle.collapsed {
}
</style>

