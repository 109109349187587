<template>
  <div class="registration-container">
    <div class="registration-content">
      <img src="/images/smiley-guy.webp" alt="Login" class="login-image">
      <h2>Welcome to Plans.lol</h2>
      <p>Manage your plans with ease.</p>
      <button v-if="!user" @click="signInWithGoogle" class="google-signin-btn">Sign in with Google</button>
      <p v-if="error" class="error-message">{{ error }}</p>
      <form v-if="user" @submit.prevent="completeRegistration">
        <h3>Complete Your Profile</h3>
        <div class="form-group">
          <label for="zipCode">Zip Code:</label>
          <input type="text" id="zipCode" v-model="zipCode" required pattern="\d{5}" title="Please enter a valid 5-digit zip code">
          <small>Used to provide local weather information</small>
        </div>
        <div class="form-group">
          <label for="githubUsername">GitHub Username (optional):</label>
          <input type="text" id="githubUsername" v-model="githubUsername" placeholder="@username">
        </div>
        <button type="submit" class="submit-btn">Complete Registration</button>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { ref as dbRef, set } from 'firebase/database';
import { auth, db } from '../firebase';

export default {
  name: 'UserRegistration',
  emits: ['registration-completed'],
  setup(props, { emit }) {
    const user = ref(null);
    const error = ref(null);
    const zipCode = ref('');
    const githubUsername = ref('');

    const signInWithGoogle = async () => {
      const provider = new GoogleAuthProvider();
      try {
        console.log('Attempting to sign in with Google...');
        const result = await signInWithPopup(auth, provider);
        user.value = result.user;
        console.log('Sign-in successful:', user.value);
      } catch (err) {
        console.error('Error signing in with Google:', err);
        error.value = 'Failed to sign in with Google. Please try again.';
      }
    };

    const completeRegistration = async () => {
      if (!user.value) return;

      try {
        await set(dbRef(db, `users/${user.value.uid}/profile`), {
          zipCode: zipCode.value,
          githubUsername: githubUsername.value,
          registrationCompleted: true
        });
        console.log('Registration completed successfully');
        emit('registration-completed');
      } catch (err) {
        console.error('Error completing registration:', err);
        error.value = 'Failed to complete registration. Please try again.';
      }
    };

    return {
      user,
      error,
      zipCode,
      githubUsername,
      signInWithGoogle,
      completeRegistration
    };
  }
};
</script>

<style scoped>
.registration-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.registration-content {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  width: 100%;
  max-width: 400px;
}

h2, h3 {
  text-align: center;
  margin-bottom: 1rem;
  color: var(--secondary-color);
}

.registration-content p {
  text-align: center;
  margin-bottom: 1rem;
  color: var(--secondary-color);
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
}

input[type="text"] {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 0.8rem;
  transition: border-color 0.15s ease-in-out;
}

input[type="text"]:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 1px var(--primary-color);
}

.google-signin-btn, .submit-btn {
  display: block;
  width: 100%;
  padding: 0.75rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 0.8rem;
  cursor: pointer;
  margin-top: 1rem;
  transition: background-color 0.15s ease-in-out;
}

.google-signin-btn:hover, .submit-btn:hover {
  background-color: #5851DB;
}

.error-message {
  color: #ff4136;
  text-align: center;
  margin-top: 1rem;
  font-size: 0.8rem;
}

small {
  display: block;
  margin-top: 0.25rem;
  color: var(--text-color);
  font-size: 0.7rem;
}

/* Dark mode styles */
:global(.dark-mode) .registration-content {
  background-color: #1A3A5E;
}

:global(.dark-mode) h2,
:global(.dark-mode) h3,
:global(.dark-mode) label,
:global(.dark-mode) input[type="text"],
:global(.dark-mode) small {
  color: var(--text-color);
}

:global(.dark-mode) input[type="text"] {
  background-color: #0A2540;
  border-color: #2D4B6D;
}

.login-image {
  max-width: 300px;
  height: auto;
  margin-bottom: 20px;
}
</style>
