
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyBjJnidWwLpUh_6Y1nOuVG_N_AM-wgXg54",
    authDomain: "management-dash.firebaseapp.com",
    databaseURL: "https://management-dash-default-rtdb.firebaseio.com",
    projectId: "management-dash",
    storageBucket: "management-dash.appspot.com",
    messagingSenderId: "129837666836",
    appId: "1:129837666836:web:c26f6c9cf2061d13accd01",
    measurementId: "G-TFMJ04QTHM"
};
