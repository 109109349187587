<template>
  <div v-if="show" class="modal-overlay" @keydown.esc="close" tabindex="0">
    <div class="modal">
      <h3>Edit Task for {{ hour }}:00</h3>
      <textarea v-model="task" placeholder="Enter your task here..."></textarea>
      <div class="modal-buttons">
        <button @click="save">Save</button>
        <button @click="close">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['show', 'hour', 'initialTask'],
  data() {
    return {
      task: this.initialTask
    }
  },
  methods: {
    save() {
      this.$emit('save', { hour: this.hour, task: this.task });
      this.close();
    },
    close() {
      this.$emit('close');
    },
    handleKeyDown(e) {
      if (e.key === 'Escape' && this.show) {
        this.close();
      }
    }
  },
  watch: {
    initialTask(newValue) {
      this.task = newValue;
    },
    show(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.$el.focus();
        });
      }
    }
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeyDown);
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  outline: none; /* Remove focus outline */
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 600px;
}

textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
}

.modal-buttons button {
  margin-left: 10px;
}

.modal h3 {
  margin: 0 0 10px 0;
}

</style>
