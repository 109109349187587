<template>
  <div id="app">
    <UserRegistration v-if="!isRegistered" @registration-completed="onRegistrationCompleted" />
    <UserSubscription v-else-if="!isSubscribed" @subscription-completed="onSubscriptionCompleted" />
    <ManagementQuadrant v-else />
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { onAuthStateChanged } from 'firebase/auth';
import { ref as dbRef, get } from 'firebase/database';
import UserRegistration from './components/UserRegistration.vue';
import UserSubscription from './components/UserSubscription.vue';
import ManagementQuadrant from './components/ManagementQuadrant.vue';
import { auth, db } from './firebase';

export default {
  components: {
    UserRegistration,
    UserSubscription,
    ManagementQuadrant
  },
  setup() {
    const isRegistered = ref(false);
    const isSubscribed = ref(false);

    const checkUserStatus = async (user) => {
      console.log('Checking user status');
      if (user) {
        console.log('User is logged in:', user.uid);
        const userProfileRef = dbRef(db, `users/${user.uid}/profile`);
        const snapshot = await get(userProfileRef);
        if (snapshot.exists()) {
          const profile = snapshot.val();
          console.log('User profile:', profile);
          isRegistered.value = profile.registrationCompleted;
          isSubscribed.value = profile.subscription?.subscribed || false;
        } else {
          console.log('User profile does not exist');
        }
      } else {
        console.log('No user logged in');
        isRegistered.value = false;
        isSubscribed.value = false;
      }
      console.log('isRegistered:', isRegistered.value);
      console.log('isSubscribed:', isSubscribed.value);
    };

    onMounted(() => {
      onAuthStateChanged(auth, checkUserStatus);
    });

    const onRegistrationCompleted = () => {
      isRegistered.value = true;
      console.log('Registration completed, isRegistered:', isRegistered.value);
    };

    const onSubscriptionCompleted = () => {
      isSubscribed.value = true;
      console.log('Subscription completed, isSubscribed:', isSubscribed.value);
    };

    watch([isRegistered, isSubscribed], (newValues, oldValues) => {
      console.log('isRegistered changed from', oldValues[0], 'to', newValues[0]);
      console.log('isSubscribed changed from', oldValues[1], 'to', newValues[1]);
    });

    return {
      isRegistered,
      isSubscribed,
      onRegistrationCompleted,
      onSubscriptionCompleted
    };
  }
};
</script>

<style>
@import './styles.css';
</style>
