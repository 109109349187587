<template>
  <div class="subscription-container">
    <h2>Choose Your Plan</h2>
    <div class="plan-options">
      <div class="plan">
        <h3>Basic Plan</h3>
        <p>$9.99/month</p>
        <button @click="handleSubscription('basic')">Select Basic Plan</button>
      </div>
      <div class="plan">
        <h3>Pro Plan</h3>
        <p>$19.99/month</p>
        <button @click="handleSubscription('pro')">Select Pro Plan</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref as dbRef, set } from 'firebase/database';
import { auth, db } from '../firebase';

export default {
  name: 'UserSubscription',
  emits: ['subscription-completed'],
  setup(props, { emit }) {
    const handleSubscription = async (plan) => {
      try {
        const user = auth.currentUser;
        if (!user) {
          console.error('No user logged in');
          return;
        }

        console.log(`Subscribing user ${user.uid} to ${plan} plan`);

        await set(dbRef(db, `users/${user.uid}/profile/subscription`), {
          plan: plan,
          subscribed: true,
          subscriptionDate: new Date().toISOString()
        });

        console.log(`Subscription to ${plan} plan completed`);
        emit('subscription-completed');
      } catch (error) {
        console.error('Error handling subscription:', error);
      }
    };

    return {
      handleSubscription
    };
  }
};
</script>

<style scoped>
.subscription-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.plan-options {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.plan {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}
</style>